import React, { useEffect, useState } from "react";
import AwarenessPage from "../components/Awareness";
import tagManagerEvents from "../utils/GoogleTagManager";
import { ApiName } from "../Constant/ApiName";
import { fetchApiPost } from "../utils/FetchApi";
import { encode as base64_encode } from "base-64";
import TagManager from "react-gtm-module";

type Props = {
  jwtToken: string | null;
};

let adNetwork = "";
let parameter: any = "";
const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.jwtToken) {
      getUrlUtm();
    }
  }, [props.jwtToken]);
  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-WF7ZM7M5",
    };
    TagManager.initialize(tagManagerArgs);
  };

  // function
  const getUrlUtm = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmSource: string | null = searchParams.get("utm_source");
    parameter = searchParams.get("parameter");

    if (utmSource) {
      checkUtms(utmSource);
    }
    getFlow(utmSource);
  };

  const checkUtms = async (utmSource: string) => {
    try {
      let data = {};
      let response = await fetchApiPost(props.jwtToken, ApiName.utm_list, data);
      console.log(response);
      if (response.success === true) {
        {
          response.result.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const getFlow = async (_utm: string | null) => {
    try {
      let data = {
        utm_source: _utm,
      };
      let response = await fetchApiPost(
        props.jwtToken,
        ApiName.awarenessFlow,
        data
      );

      if (response.success === true) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", _utm);
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.result.msisdn);
        let encodedPackage = base64_encode(response.result.subPackage);
        let encodedParameter = base64_encode(parameter);

        url = `${url}/?user=${encodedMsisdn}&package=${encodedPackage}&parameter=${encodedParameter}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();

      console.log("get flow error" + error);
    }
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return <AwarenessPage />;
};

export default AwarenessScreen;
