export const ApiName = {
  generateGwtToken: "generateTokenWeb",
  checkOperator: "checkOperator",
  checkUser: "checkUser",
  sendOtp: "sendOtpWeb",
  signupweb: "sendWebOTP",
  signupwebActive: "login/sendOTP",
  verifyOtp: "verifyWebOTP",
  verifyOtpActive: "login/verifyOTP",
  subscribe: "subscribeWeb",
  getFLow: "getFlow",
  loginWeb: "loginWeb",
  ffSubscribe: "ffSubscribe",
  utm_list: "utmList",
  unSubscribeWeb: "unsubscribeWeb",
  changePackageWeb: "updatePackage",
  package_list: "package",
  getPackages: "getPackages",
  awarenessFlow: "awarenessFlow",
};
